<template>
  <v-container>
    <h1 class="title text-center">
      Възстановяване на парола
    </h1>
    <v-row align="center" justify="center">
      <v-col lg="6" md="12" sm="12">

          <v-form lazy-validation v-model="valid" ref="form">
            <v-text-field type="email" required :rules="emailRules" label="Email" v-model="email"></v-text-field>
            <v-text-field type="password" required :rules="passwordRules" label="Парола" v-model="password"
                          v-if="$route.params.code"></v-text-field>
            <v-btn color="primary" dark @click="validate()">
              Възстанови паролата
            </v-btn>
          </v-form>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PasswordReset",
  data: () => ({
    valid: false,
    email: "",
    password: "",
    emailRules: [
      v => !!v || 'E-mail адресът е задължителен',
      v => /.+@.+\..+/.test(v) || 'E-mail адресът трябва да бъде валиден',
    ],
    passwordRules: [
      v => !!v || 'Паролата е задължителна',
      v => v.length > 6 || 'Паролата трябва да съдържа минимум 6 знака',
    ],
  }),
  methods: {
    validate() {
      let val = this.$refs.form.validate();
      if (val && !this.$route.params.code) {
        this.sendPasswordResetRequest()
      } else if (val && this.$route.params.code) {
        this.resetPassword();
      }
    },
    sendPasswordResetRequest() {
      let self = this;
      this.post('/password-reset-request', {
        email: this.email
      }).then(() => {
        self.showAlert('Успешно изпратена заявка за възстановяване на паролата')
        self.redirectWithRouter('/');
      }).catch(err => {
        self.showAlert(err.response.data.error, true)
      })
    },
    resetPassword() {
      let self = this;
      this.post('password-reset', {
        email: this.email,
        password: this.password,
        code: this.$route.params.code
      }).then(() => {
        self.showAlert('Успено възстановена парола');
        self.redirectWithRouter('/');
      }).catch(err => {
        self.showAlert(err.response.data.error, true)
      })
    }

  }
}
</script>

<style scoped>

</style>